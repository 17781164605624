import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import { Layout } from '../components/Layout'
import Image from '../components/Image'
import ContactForm from '../components/ContactForm'

const ContactoPage = ({
  data: {
    directus: { page },
  },
}: PageProps<Queries.ContactoPageQuery>) => {
  if (!page) {
    return null
  }

  return (
    <Layout pageHeader={page.page_header}>
      <div className="container">
        <div className="flex flex-col md:flex-row md:items-center md:justify-center space-y-10 md:space-y-0 md:space-x-5 lg:space-x-10">
          <div className="hidden lg:block md:w-2/5 lg:w-auto">
            {page.image && <Image image={page.image} alt="" />}
          </div>
          <ContactForm className="mt-4 lg:mt-0 max-w-xl w-full lg:max-w-full lg:w-1/2" />
        </div>
      </div>
    </Layout>
  )
}

export default ContactoPage

export { PageHead as Head } from '../components/PageHead'

export const query = graphql`
  query ContactoPage {
    directus {
      page: page_contacto {
        page_header {
          ...PageHeader
        }
        meta_description
        image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
